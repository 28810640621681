import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "../layouts/Home/"
import {graphql, useStaticQuery} from 'gatsby';
import {practicalFunctionsFr} from '../data/home/functions';
import {featuresFr} from '../data/home/features';

const IndexPageFr = () => {
  const data = useStaticQuery(graphql`
    query indexHeaderImageFr {
      image: file(relativePath: { eq: "home/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en' 
    slider={true} 
    headerImage={data.image.childImageSharp} 
    headerImageAlt="Ecran Affichage dynamique Attractif"
    title="Entreprise d’affichage dynamique axée sur le contenu" 
    subTitle="L’affichage dynamique sur écran vous permet de communiquer de façon simple, efficace et créative avec votre clientèle et vos employés." 
    anchorLink="#home-features-section">
      <Seo 
        title="Création et gestion d'affichage numérique dynamique"
        description="L’affichage dynamique sur écran par Attractif vous permet de communiquer de façon simple, efficace et créative avec votre clientèle et vos employés, partout au Canada."
       />
      <Home lang="fr-CA" practicalFunctions={practicalFunctionsFr} features={featuresFr}/>
    </Layout>
  )
}

export default IndexPageFr
